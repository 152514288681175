import { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import {
  InlineEdit,
  Input,
  InputPicker,
  Button as RsButton,
  Toggle,
} from 'rsuite';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../../services/axiosPrivate';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { QuillEditor } from '../../../../../shared/components/QuillEditor';
import { Title } from '../../../../../shared/components/Title';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { ReviewsJetPlatform } from '../../../../product/types';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import { fetchWidgetSettings } from '../../../../widgetSettings/redux/action';
import { siteWidgetSettingsSelector } from '../../../../widgetSettings/redux/widgetSettings.slice';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  padding: 0 30px;
  max-width: 1230px;
  margin: auto;
  .date {
  }
`;

const BackBtn = styled(RsButton)`
  margin-bottom: 30px;
  font-weight: bold;
`;

const Content = styled(FlexContainer)`
  width: 100%;
  margin-top: 20px;

  .left {
    flex: 1;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 500px;
    width: 70%;
    min-width: 500px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;

    .timeline {
      position: absolute;
      top: 20px;
      left: 20px;
      bottom: 20px;
      height: calc(100% - 40px);
      border-left: 1px solid #595d70;
    }
  }
`;

const Step = styled.div`
  position: relative;
  padding-left: 8px;
  width: 100%;
  margin-bottom: 30px;

  .inner-dot {
    background-color: #595e70;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
  .outer-dot {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  .font-bold {
    font-weight: bold;
    font-size: 13px;
  }
  .date {
    font-size: 13px;
  }
  .mail-content-wrapper {
    padding-left: 55px;
    padding-right: 30px;
    margin-top: 20px;

    p {
      margin-top: 0;
    }
  }

  .mail-content {
    padding: 15px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
`;

const EmailSection = styled(FlexContainer)`
  max-width: 500px;
  padding-left: 56px;
  label {
    display: block;
    margin: 10px 0 0;
  }
  .rs-input {
    margin-top: 10px;
  }
  div {
    width: 100%;
  }
`;

const SupportText = styled.p`
  font-size: 14px;
  color: #666;
  padding-left: 56px;
  margin-top: 10px;
`;

const InlineCode = styled.code`
  background-color: #f3f4f6;
  color: #ef4444;
  padding: 0.2rem;
  border-radius: 0.25rem;
  font-size: 12px;
`;

type Props = {
  onClose: () => void;
};

const data = Array.from({ length: 31 }, (_, i) => i + 1).map((item) => ({
  value: item,
  label: `${item} day${item > 1 ? 's' : ''}`,
}));

export default function Settings(props: Props) {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const [delay, setDelay] = useState(7);
  const [status, setStatus] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const { data: businessInfo } = useAppSelector((state) => state.businessInfo);
  const { account } = useAppSelector(accountSelector);
  const [saving, setSaving] = useState(Loading.INITIAL);
  const widgetSettings = useAppSelector(siteWidgetSettingsSelector);
  const { apm } = widgetSettings ?? {};
  const { ownerName = '', selectedSite } = account || {};
  const { platform } = selectedSite ?? {};
  const ownerFirstName = businessInfo?.name
    ? businessInfo.name
    : ownerName.split(' ')[0];

  const { showSuccessToast, showErrorToast } = useNotification();

  useEffect(() => {
    if (apm) {
      const { subject, message, delay, status } = apm;
      setSubject(subject);
      setDelay(delay);
      setStatus(status);
      const _message = message
        .replaceAll(/{{owner_name}}/g, ownerFirstName)
        .replaceAll(/{{ owner_name}}/g, ownerFirstName)
        .replaceAll(/{{ owner_name }}/g, ownerFirstName)
        .replaceAll(/{{owner_name }}/g, ownerFirstName);

      setMessage(_message);
    }
  }, [apm]);

  const saveSettings = async () => {
    if (!subject || !message) {
      return null;
    }

    try {
      setSaving(Loading.PENDING);
      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/settings/apm`,
        { status, delay, subject, message }
      );

      setSaving(Loading.SUCCESS);
      showSuccessToast('Settings updated successfully');
      onClose();
      dispatch(fetchWidgetSettings());
    } catch (error) {
      setSaving(Loading.ERROR);
      // @ts-ignore
      const errorMessage = error.message;
      showErrorToast(errorMessage ?? 'Failed to send review request');
    }
  };

  return (
    <Wrapper>
      <BackBtn appearance="subtle" onClick={onClose}>
        <FlexContainer gap="5px">
          <FaArrowLeft />
          <span>Back</span>
        </FlexContainer>
      </BackBtn>
      <FlexContainer justifyContent="space-between">
        <Title>After Purchase Emails</Title>
        <Button
          rounded
          onClick={saveSettings}
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'primary'}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save settings'}
        </Button>
      </FlexContainer>

      <Content justifyContent="space-between" alignItems="stretch" gap="20px">
        <div className="left">
          <div className="timeline" />
          <Step>
            <FlexContainer
              gap="10px"
              justifyContent="flex-start"
              style={{ paddingLeft: 56 }}
            >
              <span>Active</span>
              <Toggle
                checked={status}
                // @ts-ignore
                onChange={setStatus}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </FlexContainer>
          </Step>
          <Step>
            <FlexContainer justifyContent="flex-start" gap="30px">
              <div className="outer-dot">
                <div className="inner-dot"></div>
              </div>
              <FlexContainer gap="5px">
                <span className="font-bold">
                  {platform === ReviewsJetPlatform.SHOPIFY
                    ? 'When an order is fulfilled'
                    : 'When an order is created'}
                </span>
              </FlexContainer>
            </FlexContainer>
          </Step>

          <Step>
            <FlexContainer justifyContent="flex-start" gap="30px">
              <div className="outer-dot">
                <div className="inner-dot"></div>
              </div>
              <FlexContainer gap="5px">
                <span className="font-bold">Wait </span>

                <InlineEdit
                  size="sm"
                  placeholder="..."
                  showControls={false}
                  value={delay}
                  onChange={setDelay}
                >
                  <InputPicker data={data} style={{ width: 75 }} />
                </InlineEdit>
                <span className="font-bold">before sending your email</span>
              </FlexContainer>
            </FlexContainer>
          </Step>

          <Step>
            <FlexContainer justifyContent="flex-start" gap="30px">
              <div className="outer-dot">
                <div className="inner-dot"></div>
              </div>
              <FlexContainer gap="5px" justifyContent="space-between">
                <span className="font-bold">Your email </span>
                {/* <IconButton size="xs">
                  <AIicon marginRight="0" size="15px" />
                </IconButton> */}
              </FlexContainer>
            </FlexContainer>

            <EmailSection
              stack
              gap="10px"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <div className="">
                <label htmlFor="subject">Subject</label>
                <Input
                  id="subject"
                  placeholder="Add your subject"
                  value={subject}
                  onChange={setSubject}
                />
              </div>
              <div className="">
                <label htmlFor="message">Message</label>
                <QuillEditor simple content={message} onChange={setMessage} />
              </div>
            </EmailSection>
            <SupportText>
              <p>Supported variables: </p>
              {/* <p>
                <InlineCode>&#123;&#123; name &#125;&#125;</InlineCode> to
                display the customer's full name.
              </p> */}

              <p>
                <InlineCode>&#123;&#123; first_name &#125;&#125;</InlineCode> to
                display only the customer's first name.
              </p>
            </SupportText>
          </Step>
        </div>
      </Content>
    </Wrapper>
  );
}
