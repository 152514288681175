import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'rsuite';
import styled, { css } from 'styled-components';
import { z, ZodType } from 'zod';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import Preloader from '../../../../shared/components/Preloader';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getSite } from '../../../userData/redux/action';
import { accountSelector } from '../../../userData/redux/userData.slice';

/* eslint no-console: "warn" */

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-style: normal;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  outline: none !important;
  margin-bottom: 5px;
  border: 1px solid #cecece;
  &:active {
    border: 1px solid #cecece;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const ErroMessage = styled.span`
  display: block;
  color: red;
  margin-bottom: 15px;
`;

export type AccountInfo = {
  fullName: string;
  email: string;
};

const FormSchema: ZodType<AccountInfo> = z.object({
  fullName: z.string().min(2, 'Full name is required'),
  email: z.string().email('Enter a valid email address'),
});

export default function Profile() {
  const dispatch = useAppDispatch();
  const { account, loading } = useAppSelector(accountSelector);
  const { ownerEmail, personalEmail, ownerName } = account || {};
  const [saving, setSaving] = useState(Loading.INITIAL);
  const { showErrorToast, showSuccessToast } = useNotification();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountInfo>({
    resolver: zodResolver(FormSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      fullName: ownerName ?? '',
      email: personalEmail ?? ownerEmail ?? '',
    },
  });

  const onSubmit = useCallback(
    async (data: AccountInfo) => {
      if (saving === Loading.PENDING) {
        return;
      }

      try {
        setSaving(Loading.PENDING);

        await axiosPrivate.post(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/account/update`,
          { email: data.email, fullName: data.fullName }
        );

        setSaving(Loading.SUCCESS);
        showSuccessToast('Profile updated');
        dispatch(getSite({ showPreloader: false }));
      } catch (error: any) {
        showErrorToast(error.message);
      }
    },
    [saving]
  );

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  return (
    <Wrapper>
      <Header>Account info</Header>
      <Content>
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="15px"
        >
          <FormGroup>
            <Label>Full name</Label>

            <Controller
              name="fullName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  placeholder="John Wick"
                  error={Boolean(errors.fullName)}
                  {...field}
                />
              )}
            />
            {errors.fullName && (
              <ErroMessage>{errors.fullName.message}</ErroMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Email </Label>

            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  placeholder="john@wick.com"
                  error={Boolean(errors.email)}
                  {...field}
                />
              )}
            />
            {errors.email && <ErroMessage>{errors.email.message}</ErroMessage>}
          </FormGroup>
        </FlexContainer>
      </Content>
      <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'secondary'}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save info'}
        </Button>
      </Footer>
    </Wrapper>
  );
}
