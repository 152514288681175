import { useEffect, useState } from 'react';
import { IoCheckmark, IoCopy, IoEye, IoSettings } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ErrorComponent from '../../shared/components/ErrorComponent';
import AppModal from '../../shared/components/modal';
import Preloader from '../../shared/components/Preloader';
import useConfetti from '../../shared/hooks/useConfetti';
import { Loading } from '../../shared/types/review.types';
import removeTrailingSlash from '../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../store';
import { getStats } from '../emails/redux/action';
import { emailsSelector } from '../emails/redux/emails.slice';
import { selectedSiteSelector } from '../userData/redux/userData.slice';
import HintSlider from './components/HintSlider';
import InviteButton from './components/InviteButton';
import ShareButton from './components/ShareButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    #e2e8f0 20%,
    #e2e8f0 80%,
    transparent
  );
  margin: 4px 0;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #1e293b;
  margin: 0;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
`;

const Subtitle = styled.p`
  font-size: 15px;
  color: #64748b;
  margin: 0 0 40px;
  text-align: center;
  max-width: 500px;
  line-height: 1.5;
`;

const UrlDisplay = styled.div`
  background: white;
  padding: 1px;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e2e8f0;
  width: 100%;

  @media (max-width: 600px) {
    min-width: unset;
    width: 100%;
  }

  h2 {
    color: #64748b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background: #f8fafc;
  padding: 12px 16px;
  border-radius: 10px;
  min-width: 0;
`;

const UrlLink = styled.a`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-weight: bold;
  text-decoration: none;
  font-family: 'Roboto Mono', monospace;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  min-width: 0;

  &:hover {
    color: #1d4ed8;
    text-decoration: underline;
  }
`;

const CopyButton = styled.button<{ $copied?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  background: ${(props) => (props.$copied ? '#22c55e' : '#2563eb')};
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${(props) => (props.$copied ? '#16a34a' : '#1d4ed8')};
  }

  svg {
    font-size: 16px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #475569;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  min-width: 120px;

  svg {
    font-size: 18px;
  }

  &:hover {
    background: #f8fafc;
    color: #1e293b;
    border-color: #cbd5e1;
  }
`;

export default function ReviewForms() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [copied, setCopied] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const { realisticLook } = useConfetti();
  const selectedSite = useAppSelector(selectedSiteSelector);

  const {
    stats: { loading },
  } = useAppSelector(emailsSelector);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(formUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const noConfetti = searchParams.get('no-confetti');

    if (!noConfetti) {
      realisticLook();
    }

    dispatch(getStats());
  }, []);

  if (!selectedSite) {
    return null;
  }

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  if (loading === Loading.ERROR) {
    return <ErrorComponent onClick={() => dispatch(getStats())} />;
  }

  const { siteDisplayName } = selectedSite ?? {};

  const formUrl = `${removeTrailingSlash(
    process.env.REACT_APP_REVIEWSJET_WEBSITE!
  )}/w/${selectedSite.siteName}`;

  return (
    <>
      <Wrapper>
        <Title>Review form</Title>
        <Subtitle>
          Easily collect reviews from your customers using a simple link.
        </Subtitle>
        <ContentContainer>
          <UrlDisplay>
            <UrlContainer>
              <UrlLink href={formUrl} target="_blank" rel="noopener noreferrer">
                {formUrl}
              </UrlLink>
              <CopyButton onClick={handleCopy} $copied={copied}>
                {copied ? (
                  <>
                    <IoCheckmark /> Copied
                  </>
                ) : (
                  <>
                    <IoCopy /> Copy
                  </>
                )}
              </CopyButton>
            </UrlContainer>
          </UrlDisplay>
          <ActionButtons>
            <ActionButton onClick={() => navigate('/settings?tab=reviews')}>
              <IoSettings />
              Settings
            </ActionButton>
            <InviteButton
              isOpen={inviteOpen}
              ActionButton={ActionButton}
              onClose={() => setInviteOpen(false)}
              onInviteOpen={() => setInviteOpen(!inviteOpen)}
            />
            <ShareButton
              isOpen={shareOpen}
              formUrl={formUrl}
              ActionButton={ActionButton}
              siteDisplayName={siteDisplayName}
              onClose={() => setShareOpen(false)}
              onShareOpen={() => setShareOpen(!shareOpen)}
            />
            <ActionButton onClick={() => window.open(formUrl, '_blank')}>
              <IoEye />
              See Live
            </ActionButton>
          </ActionButtons>
          <Divider />
          <HintSlider />
        </ContentContainer>
      </Wrapper>
      <AppModal body={null} onModalClose={() => {}} shouldOpenModal={false} />
    </>
  );
}
