import initials from 'initials';
import { ChangeEvent, useRef } from 'react';
import { FaRegTrashCan } from 'react-icons/fa6';
import { Avatar } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';
import generateColorHsl from '../../../../shared/utils/generateColorHsl';

/* eslint no-console: "warn" */

const StyledAvatar = styled(Avatar)<{ color: string }>`
  color: #fff;
  background-color: ${({ color }) => color};
  font-size: 20px;
  font-weight: bolder;
`;

const DeleteBtn = styled.div`
  display: block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 100%;
  height: 26px;
  width: 26px;
  &:hover {
    color: #324fbe;
    background-color: #ebeef9;
  }
`;

type Props = {
  logo?: string;
  name: string;
  onLogoDeleted: () => void;
  onLogoUploaded: (value: string) => void;
};

const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/avif',
];

const MAX_FILE_SIZE = 5 * 1024 * 1024;

export default function Logo(props: Props) {
  const ref = useRef(null);
  const { name, onLogoUploaded, onLogoDeleted, logo } = props;
  const { showWarningToast } = useNotification();

  let logoUrl;

  if (logo) {
    // @ts-ignore
    if (typeof logo === 'object') {
      // @ts-ignore
      logoUrl = URL.createObjectURL(logo);
    } else if (typeof logo === 'string') {
      logoUrl = logo;
    } else {
      logoUrl = '';
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files[0].size > MAX_FILE_SIZE) {
        return showWarningToast('File size must be less than 5MB');
      }

      if (!ACCEPTED_IMAGE_TYPES.includes(files[0].type)) {
        return showWarningToast(
          'File type must be .jpeg, .jpg, .png, .webp, or .avif'
        );
      }

      // @ts-ignore
      onLogoUploaded(files[0]);
    }
  };

  return (
    <FlexContainer justifyContent="flex-start" alignItems="center" gap="30px">
      <div>
        <StyledAvatar
          size="lg"
          src={logoUrl ?? ''}
          color={
            logoUrl ? '#cdcdcd' : name ? generateColorHsl(name) : '#dddddd'
          }
        >
          {name ? initials(name).substring(0, 2).toUpperCase() : ''}
        </StyledAvatar>
      </div>
      <div>
        <p style={{ marginBottom: 10 }}>
          For optimal results, use a high-resolution, square image (min: 84 x
          84px).
        </p>

        <FlexContainer justifyContent="flex-start" gap="5px">
          <Button
            rounded
            size="small"
            type="button"
            variant="tertiary"
            onClick={() => {
              (ref.current as any)?.click();
            }}
          >
            Change
          </Button>
          {logo && (
            <DeleteBtn
              role="presentation"
              onClick={() => {
                onLogoDeleted();
              }}
            >
              <FaRegTrashCan size={16} />
            </DeleteBtn>
          )}
        </FlexContainer>
      </div>
      <input
        hidden
        value=""
        ref={ref}
        type="file"
        onChange={handleChange}
        accept=".jpeg,.jpg,.png,.webp,.avif"
      />
    </FlexContainer>
  );
}
