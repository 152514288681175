import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useRef } from 'react';
import {
  IoChatbubble,
  IoChevronDown,
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoWhatsapp,
  IoQrCode,
  IoShareSocial,
} from 'react-icons/io5';
import styled from 'styled-components';

const ShareDropdown = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  background: white;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  min-width: 200px;
  z-index: 10;
`;

const ShareOption = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  width: 100%;
  border: none;
  background: none;
  color: #475569;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f8fafc;
    color: #1e293b;
  }

  svg {
    font-size: 18px;
  }
`;

const ChevronIcon = styled(IoChevronDown)<{ $isOpen: boolean }>`
  font-size: 14px !important;
  margin-left: 2px;
  transition: transform 0.2s ease;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

interface ShareButtonProps {
  ActionButton: any;
  onShareOpen: () => void;
  isOpen: boolean;
  siteDisplayName: string;
  formUrl: string;
  onClose: () => void;
}

export default function ShareButton({
  ActionButton,
  onShareOpen,
  isOpen,
  siteDisplayName,
  formUrl,
  onClose,
}: ShareButtonProps) {
  const shareButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const qrCodeRef = useRef<SVGSVGElement | null>(null);
  const qrCodeSize = 256;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        shareButtonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !shareButtonRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  const handleShare = (platform: string) => {
    const text = 'Leave us a review!';
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        formUrl
      )}&text=${encodeURIComponent(text)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        formUrl
      )}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        formUrl
      )}`,
      whatsapp: `https://wa.me/?text=${encodeURIComponent(
        `${text} ${formUrl}`
      )}`,
      sms: `sms:?body=${encodeURIComponent(`${text} ${formUrl}`)}`,
    };

    if (platform === 'sms') {
      window.location.href = shareUrls[platform];
    } else {
      window.open(shareUrls[platform as keyof typeof shareUrls], '_blank');
    }
    onClose();
  };

  const handleDownloadQR = () => {
    if (qrCodeRef.current) {
      const svg = qrCodeRef.current;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = qrCodeSize;
      canvas.height = qrCodeSize;

      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

      img.onload = () => {
        if (ctx) {
          ctx.drawImage(img, 0, 0, qrCodeSize, qrCodeSize);
          const pngUrl = canvas.toDataURL('image/png');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = `${siteDisplayName} review form QR code.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      };
    }
    onClose();
  };

  return (
    <>
      <ActionButton ref={shareButtonRef} onClick={onShareOpen}>
        <IoShareSocial />
        Share
        <ChevronIcon $isOpen={isOpen} />
        <ShareDropdown ref={dropdownRef} $isOpen={isOpen}>
          <ShareOption onClick={() => handleShare('whatsapp')}>
            <IoLogoWhatsapp />
            WhatsApp
          </ShareOption>
          <ShareOption onClick={() => handleShare('facebook')}>
            <IoLogoFacebook />
            Facebook
          </ShareOption>
          <ShareOption onClick={() => handleShare('twitter')}>
            <IoLogoTwitter />
            Twitter
          </ShareOption>
          <ShareOption onClick={() => handleShare('linkedin')}>
            <IoLogoLinkedin />
            LinkedIn
          </ShareOption>
          <ShareOption onClick={() => handleShare('sms')}>
            <IoChatbubble />
            SMS
          </ShareOption>
          <ShareOption onClick={handleDownloadQR}>
            <IoQrCode />
            Download QR Code
          </ShareOption>
        </ShareDropdown>
      </ActionButton>
      <div style={{ display: 'none' }}>
        <QRCodeSVG
          value={formUrl}
          ref={qrCodeRef}
          size={qrCodeSize}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>
    </>
  );
}
