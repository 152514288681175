import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';

const PlatformWrapper = styled(FlexContainer)`
  margin-bottom: 20px;
  .logo {
    max-height: 40px;
    object-fit: cover;
  }

  span {
    font-weight: bold;
  }
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;

  ul {
    margin: 20px 0 25px 20px;
  }

  ul li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 3px;
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg>');
    }
  }

  a {
    color: #0f63c4;
    font-weight: bold;
  }
`;

const Dangerzone = styled(FlexContainer)`
  h4 {
    font-size: 18px;
    color: #e91b1b;
  }
`;

export default function Shopify({
  onDisconnect,
}: {
  onDisconnect: () => void;
}) {
  return (
    <Content>
      <PlatformWrapper justifyContent="flex-start" gap="15px">
        <img
          src="/images/ecommerce/shopify.svg"
          alt="Shopify"
          className="logo"
        />
        <span>Shopify</span>
      </PlatformWrapper>

      <p>Your Shopify website is now connected to your ReviewsJet account.</p>

      <p>You’ll have access to the following features:</p>

      <ul>
        <li>Showcase product reviews on your website</li>
        <li>Automatically ask customers for reviews after their purchase</li>
        <li>
          Collect and showcase the most frequently asked questions about your
          products
        </li>
      </ul>
      {/* <DangerZone
        onDisconnect={onDisconnect}
        platform={ReviewsJetPlatform.SHOPIFY}
      /> */}
    </Content>
  );
}
