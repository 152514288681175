import { useCallback, useState } from 'react';
import { FaDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';
import { Divider, Input, InputGroup, Message } from 'rsuite';
import styled, { css } from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import useConfetti from '../../../../shared/hooks/useConfetti';
import usePolling from '../../../../shared/hooks/usePolling';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import { accountSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../../widgetSettings/components/form-row';

/* eslint no-console: "warn" */

const Wrapper = styled(FlexContainer)`
  .label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  .rs-divider-horizontal {
    margin: 0;
  }
`;

const CardsWrapper = styled(FlexContainer)`
  width: 100%;
`;

const Card = styled(FlexContainer)<{ $selected: boolean }>`
  width: 50%;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px 0;
  cursor: pointer;
  ${({ $selected }) =>
    !!$selected &&
    css`
      border: 1px solid #4d24ff;
    `}

  &:hover {
    border: 1px solid rgb(153 153 153);
  }

  .wix-logo {
    margin-top: 35px;
  }

  img {
    max-height: 100px;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const StyledMessage = styled(Message)`
  width: 100%;
  .rs-message-container {
    padding: 18px 15px;
  }
`;

export default function Connect({
  onClose,
  onComplete,
}: {
  onClose: () => void;
  onComplete: (platform: ReviewsJetPlatform) => void;
}) {
  const [shop, setShop] = useState('');
  const { realisticLook } = useConfetti();
  const [selected, setSelected] = useState<'shopify' | 'wix' | null>(null);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};

  const { setIntervalSeconds, setIsEnabled } = usePolling(
    useCallback(async () => {
      const urlSection = `${selected}/check-installation`;

      let url = '';

      if (selected === 'shopify') {
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/${urlSection}?shop=${shop}.myshopify.com`;
      } else if (selected === 'wix') {
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/${urlSection}?siteId=${selectedSite?._id}`;
      }

      const { data } = await axiosPrivate.get(url);

      if (data === 'success') {
        realisticLook();
        setIsEnabled(false);

        if (selected === 'shopify') {
          onComplete(ReviewsJetPlatform.SHOPIFY);
        } else if (selected === 'wix') {
          onComplete(ReviewsJetPlatform.WIX);
        }
        onClose();
      }
    }, [selected, shop])
  );

  const openWindow = (platform: string, shop?: string) => {
    const width = 500;
    const height = 600;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    setIsEnabled(true);
    setIntervalSeconds(2);

    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/${platform}/connect?siteId=${selectedSite?._id}`;

    if (selected === 'shopify' && shop) {
      url += `&shop=${shop}`;
    }

    window.open(
      url,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left}` // Define the window properties
    );
  };

  return (
    <Wrapper stack alignItems="flex-end" justifyContent="flex-start" gap="20px">
      <CardsWrapper gap="20px" alignItems="stretch">
        <Card
          stack
          gap="15px"
          $selected={selected === 'shopify'}
          role="presentation"
          onClick={() => setSelected('shopify')}
        >
          {selected === 'shopify' ? (
            <FaDotCircle size={20} color="#4d24ff" />
          ) : (
            <FaRegCircle size={20} color="#ddd" />
          )}
          <img src="/images/ecommerce/shopify.svg" alt="Shopify" />
          <h4>Shopify</h4>
          <p>Connect your Shopify website </p>
        </Card>
        <Card
          stack
          gap="15px"
          $selected={selected === 'wix'}
          role="presentation"
          // onClick={() => {
          //   setSelected('wix');
          //   onClose();
          //   openWindow(
          //     `${removeTrailingSlash(
          //       process.env.REACT_APP_REVIEWS_JET_API!
          //     )}/wix/connect?siteId=${selectedSite?._id}`
          //     // startProcess
          //   );
          // }}

          onClick={() => {
            setSelected('wix');
            openWindow('wix');
          }}
        >
          {selected === 'wix' ? (
            <FaDotCircle size={20} color="#4d24ff" />
          ) : (
            <FaRegCircle size={20} color="#ddd" />
          )}
          <img
            src="/images/ecommerce/wix-black.svg"
            alt="Wix"
            className="wix-logo"
          />
          <h4>Wix</h4>
          <p>Connect your Wix website </p>
        </Card>
      </CardsWrapper>

      {/* {selected === 'wix' && (
        <StyledMessage header="Contact Us" style={{ width: '100%' }}>
          Email us at hello@reviewsjet.com with the subject line
          <b>"Connect Wix"</b>.
        </StyledMessage>
      )} */}

      {selected === 'shopify' && (
        <>
          <FormRow marginBottom="0">
            <span className="label">Enter your Shopify store name</span>
            <FlexContainer
              justifyContent="flex-start"
              gap="20px"
              style={{ width: '100%' }}
            >
              <InputGroup inside style={{ flex: 2 }}>
                <InputGroup.Addon>https://</InputGroup.Addon>
                <Input
                  value={shop}
                  onChange={setShop}
                  placeholder="amazing-store"
                />
                <InputGroup.Addon>.myshopify.com</InputGroup.Addon>
              </InputGroup>

              <Button
                rounded
                variant="primary"
                disabled={!shop}
                onClick={() => openWindow('shopify', `${shop}.myshopify.com`)}

                // onClick={() => {
                //   setSelected('shopify');
                //   onClose();
                //   openWindow(
                //     `${removeTrailingSlash(
                //       process.env.REACT_APP_REVIEWS_JET_API!
                //     )}/shopify/connect?siteId=${
                //       selectedSite?._id
                //     }&shop=${shop}.myshopify.com`
                //     // startProcess
                //   );
                // }}
              >
                Connect
              </Button>
            </FlexContainer>
          </FormRow>
          <Divider />
        </>
      )}

      <FlexContainer gap="10px">
        <Button rounded variant="tertiary" size="small" onClick={onClose}>
          Cancel
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
