import { useCallback, useEffect, useState } from 'react';
import { FaMailchimp } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../shared/components/modal';
import Preloader from '../../../../shared/components/Preloader';
import { useNotification } from '../../../../shared/hooks/useNotification';
import usePolling from '../../../../shared/hooks/usePolling';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { accountSelector } from '../../../userData/redux/userData.slice';
import ConfirmDisconnect from './components/ConfirmDisconnect';
import ConnectActiveCampaign from './components/ConnectActiveCampaign';
import ConnectSendGrid from './components/ConnectSendGrid';
import Item from './components/Item';
import useIntegration from './hooks/useIntegration';
import { listIntegrations } from './redux/actions';
import {
  IntegrationPlatform,
  integrationsSelector,
  isActiveCampaignConnectedSelector,
  isIntercomConnectedSelector,
  isKlaviyoConnectedSelector,
  isMailChimpConnectedSelector,
  isSendgridConnectedSelector,
} from './redux/integrations.slice';
import getPlatformName from './utils/getPlatformName';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);

  p {
    font-weight: normal;
    font-size: 14px;
    color: #5a5a5a;
  }
`;

const Body = styled.div`
  .logo {
    width: 36px;
    max-height: 36px;

    img {
      width: 100%;
      border-radius: 7px;
    }
  }
`;

const Footer = styled.div`
  padding: 15px 20px;
  font-size: 14px;
  color: #000;
  border-top: 1px solid rgb(247, 247, 247);
`;

export default function Integrations() {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { _id: siteId, platform } = selectedSite ?? {};
  const { loading } = useAppSelector(integrationsSelector);
  const isMailChimpConnected = useAppSelector(isMailChimpConnectedSelector);
  const isIntercomConnected = useAppSelector(isIntercomConnectedSelector);
  const isKlaviyoConnected = useAppSelector(isKlaviyoConnectedSelector);
  const isActiveCampaignConnected = useAppSelector(
    isActiveCampaignConnectedSelector
  );
  // const isHubspotConnected = useAppSelector(isHubspotConnectedSelector);
  const isSendgridConnected = useAppSelector(isSendgridConnectedSelector);
  const {
    isOpenDisconnectModal,
    setIsOpenDisconnectModal,
    disconnecting,
    onDisconnect,
    isOpenConnectActiveCampaignModal,
    setIsOpenConnectActiveCampaignModal,
    onConnectActiveCampaign,
    connectingActiveCampaign,
    isOpenConnectSendGridModal,
    connectingSendGrid,
    onConnectSendGrid,
    setIsOpenConnectSendGridModal,
  } = useIntegration();
  const [selectedPlatform, setSelectedPlatform] =
    useState<IntegrationPlatform | null>(null);
  const { showSuccessToast } = useNotification();

  useEffect(() => {
    dispatch(listIntegrations({ showPreloader: true }));
  }, [platform]);

  const { setIntervalSeconds, setIsEnabled } = usePolling(
    useCallback(async () => {
      let urlSegment = '';

      switch (selectedPlatform) {
        case IntegrationPlatform.ACTIVE_CAMPAIGN:
          urlSegment = 'active-campaign';
          break;

        case IntegrationPlatform.HUBSPOT:
          urlSegment = 'hubspot';
          break;

        case IntegrationPlatform.INTERCOM:
          urlSegment = 'intercom';
          break;

        case IntegrationPlatform.KLAVIYO:
          urlSegment = 'klaviyo';
          break;

        case IntegrationPlatform.MAILCHIMP:
          urlSegment = 'mailchimp';
          break;

        case IntegrationPlatform.SENDGRID:
          urlSegment = 'sendgrid';
          break;

        default:
          break;
      }

      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/${urlSegment}/check-installation?siteId=${siteId}`;

      const { data } = await axiosPrivate.get(url);

      if (data === 'success') {
        setIsEnabled(false);
        showSuccessToast('Account successfully connected');
        dispatch(listIntegrations({ showPreloader: false }))
          .then(() => {})
          .catch(() => {});
      }
    }, [siteId, selectedPlatform])
  );

  const openWindow = (urlSegment: string, _width?: number) => {
    const width = _width ?? 500;
    const height = 600;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    setIsEnabled(true);
    setIntervalSeconds(2);

    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/${urlSegment}/connect?siteId=${siteId}`;

    window.open(
      url,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  return (
    <>
      <Wrapper>
        <Header>
          <span>Integrations</span>
          <p>
            Connect your account to send review requests directly to your
            contacts.
          </p>
        </Header>
        <Body>
          <Item
            title={getPlatformName(IntegrationPlatform.MAILCHIMP)}
            isConnected={isMailChimpConnected}
            logo={<FaMailchimp size={36} />}
            onConnect={() => {
              setSelectedPlatform(IntegrationPlatform.MAILCHIMP);
              openWindow('mailchimp');
            }}
            onDisconnect={() => {
              setSelectedPlatform(IntegrationPlatform.MAILCHIMP);
              setIsOpenDisconnectModal(true);
            }}
          />

          {/* <Item
            logo={<FaIntercom size={36} />}
            title={getPlatformName(IntegrationPlatform.INTERCOM)}
            isConnected={isIntercomConnected}
            onDisconnect={() => {
              setSelectedPlatform(IntegrationPlatform.INTERCOM);
              setIsOpenDisconnectModal(true);
            }}
            onConnect={() => {
              setSelectedPlatform(IntegrationPlatform.INTERCOM);
              openWindow('intercom');
            }}
          /> */}

          <Item
            title={getPlatformName(IntegrationPlatform.KLAVIYO)}
            isConnected={isKlaviyoConnected}
            onDisconnect={() => {
              setSelectedPlatform(IntegrationPlatform.KLAVIYO);
              setIsOpenDisconnectModal(true);
            }}
            logo={
              <div className="logo">
                <img src="/images/klaviyo-2.jpg" alt="" />
              </div>
            }
            onConnect={() => {
              setSelectedPlatform(IntegrationPlatform.KLAVIYO);
              openWindow('klaviyo');
            }}
          />

          <Item
            title={getPlatformName(IntegrationPlatform.ACTIVE_CAMPAIGN)}
            isConnected={isActiveCampaignConnected}
            onDisconnect={() => {
              setSelectedPlatform(IntegrationPlatform.ACTIVE_CAMPAIGN);
              setIsOpenDisconnectModal(true);
            }}
            logo={
              <div className="logo">
                <img src="/images/active-campaign.png" alt="" />
              </div>
            }
            onConnect={() => {
              setIsOpenConnectActiveCampaignModal(true);
            }}
          />

          <Item
            title={getPlatformName(IntegrationPlatform.SENDGRID)}
            isConnected={isSendgridConnected}
            onDisconnect={() => {
              setSelectedPlatform(IntegrationPlatform.SENDGRID);
              setIsOpenDisconnectModal(true);
            }}
            logo={
              <div className="logo">
                <img src="/images/sendgrid.png" alt="" />
              </div>
            }
            onConnect={() => {
              setIsOpenConnectSendGridModal(true);
            }}
          />
          {/* 
          <Item
            title={getPlatformName(IntegrationPlatform.HUBSPOT)}
            isConnected={isHubspotConnected}
            onDisconnect={() => {
              setSelectedPlatform(IntegrationPlatform.HUBSPOT);
              setIsOpenDisconnectModal(true);
            }}
            logo={
              <div className="logo">
                <img src="/images/hubspot.jpg" alt="" />
              </div>
            }
            onConnect={() => {
              setSelectedPlatform(IntegrationPlatform.HUBSPOT);
              openWindow('hubspot');
            }}
          /> */}
        </Body>
        <Footer>
          <FlexContainer justifyContent="flex-start" gap="5px" flexWrap="wrap">
            <span>
              Click <Link to="/forms">here</Link> to request reviews from your
              contacts.
            </span>
          </FlexContainer>
        </Footer>
      </Wrapper>

      <AppModal
        size="xs"
        shouldOpenModal={isOpenDisconnectModal}
        onModalClose={() => setIsOpenDisconnectModal(false)}
        body={
          <ConfirmDisconnect
            onClose={() => setIsOpenDisconnectModal(false)}
            selectedPlatform={selectedPlatform}
            onDisconnect={onDisconnect}
            isDisconnecting={disconnecting === Loading.PENDING}
          />
        }
      />

      <AppModal
        size="sm"
        shouldOpenModal={isOpenConnectActiveCampaignModal}
        onModalClose={() => setIsOpenConnectActiveCampaignModal(false)}
        body={
          <ConnectActiveCampaign
            onClose={() => setIsOpenConnectActiveCampaignModal(false)}
            onConnect={onConnectActiveCampaign}
            isConnecting={connectingActiveCampaign === Loading.PENDING}
          />
        }
      />

      <AppModal
        size="sm"
        shouldOpenModal={isOpenConnectSendGridModal}
        onModalClose={() => setIsOpenConnectSendGridModal(false)}
        body={
          <ConnectSendGrid
            onClose={() => setIsOpenConnectSendGridModal(false)}
            onConnect={onConnectSendGrid}
            isConnecting={connectingSendGrid === Loading.PENDING}
          />
        }
      />
    </>
  );
}
