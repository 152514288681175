import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import { getSite } from '../../../userData/redux/action';
import { accountSelector } from '../../../userData/redux/userData.slice';
import AfterPurchaseMail from './AfterPurchaseMail';
import Disconnect from './Disconnect';
import EmptySpace from './EmptySpace';
import Shopify from './Shopify';
import Wix from './Wix';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

export default function Ecommerce() {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};
  const [userPlatform, setPlatform] = useState<
    ReviewsJetPlatform | null | undefined
  >(null);
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const [submitting, setSubmitting] = useState(Loading.INITIAL);
  const { showErrorToast, showSuccessToast } = useNotification();

  const onDisconnect = async () => {
    try {
      setSubmitting(Loading.PENDING);
      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/disconnect-ecommerce`
      );
      setSubmitting(Loading.SUCCESS);
      showSuccessToast(
        'Your eCommerce site is disconnected from your ReviewsJet account.'
      );
      dispatch(getSite({ showPreloader: false }));
      handleCloseModal();
    } catch (error: any) {
      showErrorToast(error.message);
      setSubmitting(Loading.ERROR);
    }
  };

  useEffect(() => {
    setPlatform(platform);
  }, [platform]);

  if (!userPlatform) {
    return null;
  }

  return (
    <>
      {[ReviewsJetPlatform.WIX, ReviewsJetPlatform.SHOPIFY].includes(
        userPlatform
      ) ? (
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Wrapper>
            <Header>
              <span>Connected eCommerce Platform</span>
            </Header>
            {platform === ReviewsJetPlatform.WIX && (
              <Wix onDisconnect={handleOpenModal} />
            )}
            {platform === ReviewsJetPlatform.SHOPIFY && (
              <Shopify onDisconnect={handleOpenModal} />
            )}
          </Wrapper>
          <AfterPurchaseMail />
        </FlexContainer>
      ) : (
        <EmptySpace
          onComplete={(platform: ReviewsJetPlatform) => {
            setPlatform(platform);
            dispatch(getSite({ showPreloader: false }));
          }}
        />
      )}

      <AppModal
        size="xs"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <Disconnect
            onClose={handleCloseModal}
            submitting={submitting}
            onDisconnect={onDisconnect}
          />
        }
      />
    </>
  );
}
