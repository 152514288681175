import { useState } from 'react';
import { Drawer } from 'rsuite';
import styled from 'styled-components';
import {
  ActiveDot,
  InactiveDot,
} from '../../../../../shared/components/ActiveDot';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import useTheme from '../../../../../shared/hooks/useTheme';
import { useAppSelector } from '../../../../../store';
import { ReviewsJetPlatform } from '../../../../product/types';
import { selectedSiteSelector } from '../../../../userData/redux/userData.slice';
import FormRow from '../../../../widgetSettings/components/form-row';
import { siteWidgetSettingsSelector } from '../../../../widgetSettings/redux/widgetSettings.slice';
import Settings from './Settings';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
`;

const StyledDrawer = styled(Drawer)<{ $backgroundColor: string }>`
  .rs-drawer-content {
    background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
  }
`;

const Header = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;

  ul {
    margin: 20px 0 25px 20px;
  }

  ul li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 3px;
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg>');
    }
  }

  a {
    color: #0f63c4;
    font-weight: bold;
  }
`;

export default function AfterPurchaseMail() {
  const { pageBackgroundColor } = useTheme();
  const [openSettings, setOpenSettings] = useState(false);
  const widgetSettings = useAppSelector(siteWidgetSettingsSelector);
  const { apm } = widgetSettings ?? {};
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { platform } = selectedSite ?? {};

  if (!apm) {
    return null;
  }

  const { status, delay } = apm;

  return (
    <>
      <Wrapper>
        <Header justifyContent="space-between">
          <span>After-Purchase Emails</span>
          <Button
            rounded
            size="small"
            variant="secondary"
            onClick={() => setOpenSettings(true)}
          >
            Settings
          </Button>
        </Header>
        <Content>
          <FormRow
            alignItems="center"
            justifyContent="flex-start"
            gap="50px"
            marginBottom="10px"
          >
            <FlexContainer justifyContent="flex-start" gap="8px">
              <span>Status:</span>
              {!!status ? (
                <>
                  <b>ON</b> <ActiveDot size={10} />
                </>
              ) : (
                <>
                  <b>OFF</b> <InactiveDot size={10} />
                </>
              )}
            </FlexContainer>
          </FormRow>

          <FormRow
            alignItems="center"
            justifyContent="flex-start"
            gap="50px"
            marginBottom="10px"
          >
            <p>
              {!!status
                ? `A review request is sent ${delay} day${
                    delay > 1 ? 's' : ''
                  } after order fulfillment.`
                : 'No review request is sent after order fulfillment.'}
            </p>
          </FormRow>
        </Content>
      </Wrapper>

      <StyledDrawer
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        size={
          platform === ReviewsJetPlatform.WIX
            ? 'calc(100% - 85px)'
            : 'calc(100% - 255px)'
        }
        backdrop={false}
        className="email-details-drawer"
        $backgroundColor={pageBackgroundColor}
      >
        <Drawer.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Settings onClose={() => setOpenSettings(false)} />
        </Drawer.Body>
      </StyledDrawer>
    </>
  );
}
