import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../shared/types/review.types';

const Wrapper = styled.div`
  h3 {
    font-size: 18px;
  }

  p {
    margin-bottom: 30px;
  }
`;

export default function Disconnect({
  onClose,
  submitting,
  onDisconnect,
}: {
  onClose: () => void;
  submitting: Loading;
  onDisconnect: () => Promise<void>;
}) {
  return (
    <Wrapper>
      <h3>Are you sure?</h3>
      <p>Click the Confirm to proceed.</p>

      <FlexContainer justifyContent="flex-end" gap="10px">
        <Button rounded variant="tertiary" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          rounded
          variant="danger"
          size="small"
          disabled={submitting === Loading.PENDING}
          onClick={onDisconnect}
        >
          {submitting === Loading.PENDING ? 'Please wait...' : 'Confirm'}
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
