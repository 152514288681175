import { Suspense, useEffect } from 'react';

import { batch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Message } from 'rsuite';
import styled, { css } from 'styled-components';
import {
  OnboardingStep,
  setCurrentStep,
  setPersonalInformation,
} from '../../features/onboarding/redux/onboarding.slice';
import { ReviewsJetPlatform } from '../../features/product/types';
import { getSite } from '../../features/userData/redux/action';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import DiscountCode from '../../shared/components/DiscountCode';
import Feedback from '../../shared/components/Feedback';
import Preloader from '../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../shared/components/ReviewsJetCrownLogo';
import Sidebar from '../../shared/components/layouts/sidebar';
import useToggleSidebar from '../../shared/hooks/useToggleSidebar';
import { Loading, SubscriptionPlan } from '../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../store';
import MenuDropdown from './MenuDropdown';
import SeatSelector from './SeatSelector';

/* eslint no-console: "warn" */

const Wrapper = styled.div<{
  collapsed: boolean;
  $hideSidebar: boolean;
  $platform?: ReviewsJetPlatform;
}>`
  transition: width 0.15s;
  ${({ $platform, $hideSidebar }) => {
    if ($platform === ReviewsJetPlatform.WIX) {
      return css`
        width: ${$hideSidebar ? '100%' : 'calc(100% - 84px)'};
      `;
    } else {
      return css`
        width: ${$hideSidebar ? '100%' : 'calc(100% - 255px)'};
      `;
    }
  }}

  ${({ collapsed, $hideSidebar }) => {
    if (!collapsed && !$hideSidebar) {
      return css`
        margin-left: auto;
        width: calc(100% - 265px);
      `;
    }

    return css`
      margin-left: auto;
    `;
  }}
`;

const LeftWrapper = styled.div`
  flex: 1;
  .rs-btn-icon.rs-btn-md {
    padding: 0;
  }
`;
const OutletWrapper = styled.div`
  transition: width 0.15s;
`;
const Topbar = styled.div<{
  $hideSidebar: boolean;
  $collapsedSidebar: boolean;
}>`
  padding: 15px 20px;
  height: 76px;
  border-bottom: 1px solid #e4e4e4;
  position: fixed;
  background: #fff;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ $hideSidebar }) => ($hideSidebar ? '100%' : 'calc(100% - 84px)')};
  display: none;
`;

const InnerWrapper = styled.div<{
  $hideSidebar: boolean;
  $plan?: SubscriptionPlan;
}>`
  ${({ $hideSidebar, $plan }) =>
    !$hideSidebar &&
    css`
      padding-top: ${() => {
        const path = window.location.pathname;
        return path.includes('/manage-reviews') &&
          $plan === SubscriptionPlan.FREE
          ? '10px'
          : '80px';
      }};
    `}
`;

const RightWrapper = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
  .profile-wrapper {
    position: absolute;
    top: 5px;
    right: 2px;
  }
`;
const UninstalledWrapper = styled.div`
  padding: 20px 30px;
`;

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, account } = useAppSelector(accountSelector);
  const { selectedSite, ownerName = '' } = account ?? {};
  const { platform, onboarding, url = '', plan } = selectedSite ?? {};
  const { hideSidebar, collapsedSidebar, setCollapsedSidbar } =
    useToggleSidebar();
  const {
    step: onboardingStep,
    sellProduct,
    hasExistingReviews,
    reference = '',
  } = onboarding ?? {};

  const [firstName, lastName] = ownerName?.split(' ') ?? [];

  useEffect(() => {
    dispatch(getSite({ showPreloader: true })).catch(() => {});
  }, []);

  useEffect(() => {
    if (
      onboardingStep &&
      onboardingStep !== OnboardingStep.Complete &&
      platform !== ReviewsJetPlatform.SHOPIFY
    ) {
      batch(() => {
        dispatch(setCurrentStep(onboardingStep));
        dispatch(
          setPersonalInformation({
            firstName,
            lastName,
            reference,
            websiteUrl: url,
            sellProducts: !!sellProduct ? 'Yes' : 'No',
            hasExistingTestimonials: !!hasExistingReviews ? 'Yes' : 'No',
          })
        );
        navigate('/onboarding');
      });
    }
  }, [onboardingStep, platform]);

  return (
    <>
      <Sidebar
        hideSidebar={hideSidebar}
        collapsedSidebar={collapsedSidebar}
        toggleSidebar={(value) => setCollapsedSidbar(value)}
      />
      <Wrapper
        collapsed={collapsedSidebar}
        $hideSidebar={hideSidebar}
        $platform={platform}
      >
        <Suspense fallback={<Preloader />}>
          <OutletWrapper>
            <Topbar
              $hideSidebar={hideSidebar}
              $collapsedSidebar={collapsedSidebar}
            >
              <LeftWrapper>
                {hideSidebar ? (
                  <ReviewsJetCrownLogo
                    onClick={() => navigate('/manage-reviews')}
                  />
                ) : (
                  <SeatSelector />
                )}
              </LeftWrapper>
              <DiscountCode />
              <RightWrapper style={{ flex: 1 }}>
                <MenuDropdown />
              </RightWrapper>
            </Topbar>
            <InnerWrapper $hideSidebar={hideSidebar} $plan={plan}>
              {loading === Loading.SUCCESS && !!selectedSite?.unInstalled && (
                <UninstalledWrapper>
                  <Message type="error" showIcon>
                    <span>
                      {' '}
                      ReviewsJet has been uninstalled from{' '}
                      <b>{selectedSite.url}</b>. Please reinstall to continue
                      using this workspace.
                    </span>
                  </Message>
                </UninstalledWrapper>
              )}

              {/* <Discount /> */}

              <Outlet />
            </InnerWrapper>
          </OutletWrapper>
        </Suspense>
      </Wrapper>
      <Feedback />
    </>
  );
};

export default PrivateRoute;
