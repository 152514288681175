import styled, { css } from 'styled-components';

const Dot = styled.div<{ $size: number; $active?: boolean }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background-color: ${({ $active }) => ($active ? 'green' : '#cd0d0a')};
  border-radius: 50%;
  position: relative;
  ${({ $active }) =>
    $active &&
    css`
      animation: pulse 1.5s infinite;
    `}

  ${({ $active }) =>
    $active
      ? css`
          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
            }
            70% {
              box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
            }
          }
        `
      : ''}
`;

const ActiveDot = ({ size = 10 }: { size?: number }) => {
  return <Dot $size={size} $active={true} />;
};

const InactiveDot = ({ size = 10 }: { size?: number }) => {
  return <Dot $size={size} $active={false} />;
};

export { ActiveDot, InactiveDot };
