import { useEffect, useMemo, useRef } from 'react';
import {
  IoCart,
  IoChevronDown,
  IoMail,
  IoMegaphone,
  IoPerson,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import AppModal from '../../../shared/components/modal';
import useModal from '../../../shared/components/modal/useModal';
import { getLimit } from '../../../shared/utils/usage-limits';
import { useAppDispatch, useAppSelector } from '../../../store';
import { emailsSelector } from '../../emails/redux/emails.slice';
import {
  accountSelector,
  isEcommerceSelector,
} from '../../userData/redux/userData.slice';
import MarketingPlatforms from './MarketingPlatforms';
import CreditLimitModal from './CreditLimitModal';
import ConnectEcommerceModal from './ConnectEcommerceModal';

const InviteDropdown = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  background: white;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  min-width: 220px;
  z-index: 10;
`;

const InviteOption = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  width: 100%;
  border: none;
  background: none;
  color: #475569;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f8fafc;
    color: #1e293b;
  }

  svg {
    font-size: 18px;
    color: #64748b;
  }
`;

const ChevronIcon = styled(IoChevronDown)<{ $isOpen: boolean }>`
  font-size: 14px !important;
  margin-left: 2px;
  transition: transform 0.2s ease;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

interface InviteButtonProps {
  ActionButton: any;
  onInviteOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function InviteButton({
  ActionButton,
  onInviteOpen,
  isOpen,
  onClose,
}: InviteButtonProps) {
  const inviteButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCommerce = useAppSelector(isEcommerceSelector);

  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const {
    stats: { totalSentThisMonth },
  } = useAppSelector(emailsSelector);
  const {
    handleCloseModal: handleCloseCreditLimitModal,
    handleOpenModal: handleOpenCreditLimitModal,
    showModal: showCreditLmitModal,
  } = useModal();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const {
    handleOpenModal: handleOpenMarketingPlatformsModal,
    handleCloseModal: handleCloseMarketingPlatformsModal,
    showModal: showMarketingPlatformsModal,
  } = useModal();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        inviteButtonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !inviteButtonRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  const emailsCredit = getLimit(plan)?.reviewsRequests;

  const canRequest = useMemo(() => {
    return emailsCredit > totalSentThisMonth;
  }, [emailsCredit, totalSentThisMonth]);

  return (
    <>
      <ActionButton ref={inviteButtonRef} onClick={onInviteOpen}>
        <IoMail />
        Invite
        <ChevronIcon $isOpen={isOpen} />
        <InviteDropdown ref={dropdownRef} $isOpen={isOpen}>
          <InviteOption
            onClick={() => {
              onClose();

              if (canRequest) {
                navigate('/share-form');
              } else {
                handleOpenCreditLimitModal();
              }
            }}
          >
            <IoPerson />
            Single Customer
          </InviteOption>
          <InviteOption
            onClick={() => {
              onClose();

              if (isCommerce) {
                if (canRequest) {
                  navigate('/share-form?source=orders');
                } else {
                  handleOpenCreditLimitModal();
                }
              } else {
                handleOpenModal();
              }
            }}
          >
            <IoCart />
            From Orders
          </InviteOption>
          <InviteOption
            onClick={() => {
              onClose();
              handleOpenMarketingPlatformsModal();
            }}
          >
            <IoMegaphone />
            From Marketing Lists
          </InviteOption>
        </InviteDropdown>
      </ActionButton>
      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={<ConnectEcommerceModal onClose={handleCloseModal} />}
      />
      <AppModal
        size="sm"
        shouldOpenModal={showCreditLmitModal}
        onModalClose={handleCloseCreditLimitModal}
        body={<CreditLimitModal onClose={handleCloseCreditLimitModal} />}
      />

      <AppModal
        size="xs"
        shouldOpenModal={showMarketingPlatformsModal}
        onModalClose={handleCloseMarketingPlatformsModal}
        body={
          <MarketingPlatforms onClose={handleCloseMarketingPlatformsModal} />
        }
        footer={
          <FlexContainer justifyContent="flex-end">
            <Button onClick={handleCloseMarketingPlatformsModal}>Close</Button>
          </FlexContainer>
        }
      />
    </>
  );
}
