import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import { ReviewsJetPlatform } from '../../../product/types';
import Connect from './Connect';

const Wrapper = styled(FlexContainer)`
  padding-top: 20px;
  padding: 30px;
  gap: 50px;

  .left {
    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 20px;
    }

    ul {
      margin-left: 20px;
      margin-bottom: 25px;
    }

    ul li {
      position: relative;
      margin-bottom: 10px;

      &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 3px;
        width: 1em;
        height: 1em;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg>');
      }
    }
  }
  .right {
    .img {
      width: 400px;
      height: 400px;
      background-color: #535353;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

        .circle-image {
          transform: scale(1.1);
        }
      }

      @media (max-width: 1352px) {
        width: 100%;
        width: 400px;
        height: 400px;
      }

      .circle-image {
        width: 35%; /* Adjust image size relative to container */
        object-fit: cover;
        margin: 5px; /* Space between the images */
        transition: transform 0.3s ease;
      }
    }
  }

  @media (max-width: 1097px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

type Props = {
  onComplete: (platform: ReviewsJetPlatform) => void;
};

export default function EmptySpace({ onComplete }: Props) {
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  return (
    <>
      <Wrapper justifyContent="space-between">
        <div className="left">
          <h3>Ecommerce</h3>
          <p>
            Is your website built on Shopify or Wix? Connect it now to unlock
            these features:
          </p>
          <ul>
            <li>Showcase product reviews on your website</li>
            <li>Request reviews from customers with past orders</li>
            <li>
              Automatically ask customers for reviews after their purchase
            </li>
            <li>
              Collect and showcase the most frequently asked questions about
              your products
            </li>
          </ul>

          <Button rounded variant="primary" onClick={handleOpenModal}>
            Get Started
          </Button>
        </div>
        <div className="right">
          <div className="img image-circle">
            <img
              src="/images/ecommerce/shopify.svg"
              alt="Shopify"
              className="circle-image"
            />
            <img
              src="/images/ecommerce/wix.svg"
              alt="Wix"
              className="circle-image wix"
            />
          </div>
        </div>
      </Wrapper>
      <AppModal
        title="Connect your website"
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={<Connect onClose={handleCloseModal} onComplete={onComplete} />}
      />
    </>
  );
}
