import { useEffect, useState } from 'react';
import { Divider, Progress, Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import usePlanDescriptor from '../../../../shared/hooks/usePlanDescriptor';
import useUsage from '../../../../shared/hooks/useUsage';
import {
  Loading,
  SubscriptionPlan,
} from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleUpgradeModal } from '../../../../store/app.slice';
import { emailStatsSelector } from '../../../emails/redux/emails.slice';
import { reviewsUsageSelector } from '../../../manage-reviews/redux/reviews.slice';
import {
  accountSelector,
  userCreditSelector,
} from '../../../userData/redux/userData.slice';
import useCancelSubscription from '../../hooks/useCancelSubscription';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import WarningDuplicateSubscription from './WarningDuplicateSubscription';

/* eslint no-console: "warn" */

const Label = styled.label``;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;

  .rs-progress-line {
    padding: 8px 12px 8px 0;
  }
`;

const Header = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const Footer = styled.div`
  padding: 15px 20px;
  font-size: 14px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

const Content = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;

  .section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
`;

const StyledContainer = styled(FlexContainer)`
  margin-bottom: -5px;
`;

const ProgressLine = styled(Progress.Line)`
  margin-bottom: 10px;
`;

const getUsageColor = (percentage: number | null) => {
  if (percentage !== null) {
    if (percentage < 50) {
      return '#3498ff';
    }

    if (percentage >= 50 && percentage < 80) {
      return '#f1b412';
    }

    return '#ff3737';
  }
};

export default function Plan() {
  const dispatch = useAppDispatch();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const {
    handleCloseModal: handleCloseChangePlanModal,
    handleOpenModal: handleOpenChangePlanModal,
    showModal: showChangePlanModal,
  } = useModal();

  const { textReviewsPerc, videoReviewsPerc, reviewRequestsPerc } = useUsage();
  const [showUpgrade, setShowUpgrade] = useState(false);
  const {
    stats: { totalSentThisMonth },
  } = useAppSelector(emailStatsSelector);
  const {
    totalReviewRequestsCredit,
    totalTextReviewsCredit,
    totalVideoReviewsCredit,
  } = useAppSelector(userCreditSelector);

  const {
    textReviewsPublished,
    videoReviewsPublished,
    loading: loadingReviewsUsage,
  } = useAppSelector(reviewsUsageSelector);
  const { account, loading } = useAppSelector(accountSelector);
  const { selectedSite } = account || {};
  const { plan, stripe } = selectedSite ?? {};
  const { cancelPlatform } = useCancelSubscription();
  const { planDescription } = usePlanDescriptor();

  useEffect(() => {
    if (plan !== SubscriptionPlan.ADVANCED) {
      setShowUpgrade(true);
    }
  }, [plan, textReviewsPerc, videoReviewsPerc, reviewRequestsPerc]);

  useEffect(() => {
    if (cancelPlatform) {
      handleOpenModal();
    }
  }, [cancelPlatform]);

  return (
    <>
      <Wrapper>
        <Header justifyContent="space-between">
          <span>Plan</span>
        </Header>
        <Content>
          <div className="section">
            <h3 className="title">Current plan</h3>
            <p style={{ marginBottom: 20 }}>{planDescription}</p>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <Button
                rounded
                size="small"
                onClick={() => {
                  if (!stripe && plan !== SubscriptionPlan.FREE) {
                    handleOpenChangePlanModal();
                  } else {
                    dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
                  }
                }}
              >
                Change plan
              </Button>
              {plan !== SubscriptionPlan.FREE && (
                <RsButton
                  appearance="link"
                  color="red"
                  onClick={() => {
                    if (!stripe) {
                      return window.open(
                        'https://support.wix.com/en/article/canceling-an-app-subscription',
                        '_blank'
                      );
                    } else {
                      handleOpenModal();
                    }
                  }}
                >
                  Cancel subscription
                </RsButton>
              )}
            </FlexContainer>
          </div>
          <Divider />

          <div className="section">
            <h3 className="title">Account usage</h3>
            <FlexContainer
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="10px"
            >
              <StyledContainer
                justifyContent="flex-start"
                alignItems="center"
                gap="10px"
              >
                <Label>Published text reviews</Label> <span>- </span>
                <span>
                  {loadingReviewsUsage === Loading.PENDING
                    ? '-'
                    : textReviewsPublished}{' '}
                  of {totalTextReviewsCredit}
                </span>
              </StyledContainer>
              <ProgressLine
                showInfo={false}
                strokeColor={getUsageColor(textReviewsPerc)}
                percent={textReviewsPerc}
              />
            </FlexContainer>

            <FlexContainer
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="10px"
            >
              <StyledContainer
                justifyContent="flex-start"
                alignItems="center"
                gap="10px"
              >
                <Label>Published video reviews</Label> <span>- </span>
                <span>
                  {loadingReviewsUsage === Loading.PENDING
                    ? '-'
                    : videoReviewsPublished}{' '}
                  of {totalVideoReviewsCredit}
                </span>
              </StyledContainer>
              <ProgressLine
                showInfo={false}
                strokeColor={getUsageColor(videoReviewsPerc)}
                percent={videoReviewsPerc}
              />
            </FlexContainer>

            <FlexContainer
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="10px"
            >
              <StyledContainer
                justifyContent="flex-start"
                alignItems="center"
                gap="10px"
              >
                <Label>Review requests</Label> <span>- </span>
                <span>
                  {loading === Loading.PENDING ? '-' : totalSentThisMonth} of{' '}
                  {totalReviewRequestsCredit} / month
                </span>
              </StyledContainer>
              <ProgressLine
                showInfo={false}
                strokeColor={getUsageColor(reviewRequestsPerc)}
                percent={reviewRequestsPerc}
              />
            </FlexContainer>
          </div>
        </Content>
        {showUpgrade && (
          <Footer>
            <FlexContainer justifyContent="space-between">
              <span>
                To publish more reviews or send additional review requests,
                consider upgrading your plan.
              </span>
            </FlexContainer>
          </Footer>
        )}
      </Wrapper>
      <AppModal
        size="xs"
        shouldOpenModal={showModal}
        body={<CancelSubscriptionModal onClose={handleCloseModal} />}
        onModalClose={handleCloseModal}
      />

      <AppModal
        size="sm"
        shouldOpenModal={showChangePlanModal}
        body={<WarningDuplicateSubscription />}
        onModalClose={handleCloseChangePlanModal}
      />
    </>
  );
}
