import { FaShopify, FaWix } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import FlexContainer from '../../../shared/components/layouts/flex-container';

/* eslint no-console: "warn" */

const InnerWrapper = styled(FlexContainer)`
  width: 100%;
  margin-top: 10px;

  .left {
    max-width: 350px;
    flex: 1;

    .title {
      font-size: 20px;
      margin-top: 0;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .desc {
      margin-bottom: 20px;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .right {
    flex: 1;
    max-width: 210px;
  }
`;

const Wrapper = styled(FlexContainer)``;

export default function ConnectEcommerceModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const navigate = useNavigate();

  return (
    <Wrapper
      stack
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="20px"
    >
      <InnerWrapper
        justifyContent="flex-start"
        // alignItems="flex-start"
        gap="20px"
      >
        <div className="left">
          <h3 className="title">
            Collect Reviews from <br /> Past Orders
          </h3>
          <p className="desc">
            Invite customers from previous orders to share their feedback. To
            get started, connect your eCommerce website (Wix, Shopify, etc.).
          </p>

          <Button
            rounded
            onClick={() => {
              onClose();

              navigate('/settings?tab=ecommerce');
            }}
          >
            Connect Your Store
          </Button>

          <p>
            Once connected, you can access your order history and send review
            requests in no time!
          </p>
        </div>
        <FlexContainer className="right" gap="20px">
          <FaWix size={75} />
          <FaShopify size={75} />
        </FlexContainer>
      </InnerWrapper>

      <FlexContainer justifyContent="flex-end" style={{ width: '100%' }}>
        <Button rounded size="small" variant="tertiary" onClick={onClose}>
          Not now
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
