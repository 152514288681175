import { useEffect, useState } from 'react';
import {
  IoEye,
  IoLogoWhatsapp,
  IoMail,
  IoSettings,
  IoShareSocial,
} from 'react-icons/io5';
import styled from 'styled-components';

const HintText = styled.div`
  color: #64748b;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 32px;
  background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  width: 100%;
  min-height: 180px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
    border-color: #cbd5e1;

    .hint-slide {
      transition-duration: 0s;
    }
  }
`;

const HintContent = styled.div`
  position: relative;
  width: 100%;
  height: 110px;
  margin-bottom: 0;
`;

const HintSlide = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  opacity: ${(props) => (props.$active ? 1 : 0)};
  transform: translateY(${(props) => (props.$active ? '0' : '20px')})
    scale(${(props) => (props.$active ? '1' : '0.98')});
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  pointer-events: ${(props) => (props.$active ? 'auto' : 'none')};
`;

const HintTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1e293b;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.01em;

  svg {
    font-size: 20px;
    color: #3b82f6;
    transition: all 0.3s ease;
  }
`;

const HintDetails = styled.span`
  color: #64748b;
  font-size: 14px;
  line-height: 1.6;
  max-width: 420px;
  transition: all 0.3s ease;

  strong {
    color: #334155;
    font-weight: 500;
  }
`;

const HintDots = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  position: absolute;
  bottom: 28px;
  left: 0;
  right: 0;
`;

const HintDot = styled.button<{ $active: boolean }>`
  width: ${(props) => (props.$active ? '24px' : '8px')};
  height: 8px;
  border-radius: 12px;
  background: ${(props) => (props.$active ? '#3b82f6' : '#e2e8f0')};
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${(props) => (props.$active ? 1 : 0.7)};

  &:hover {
    background: ${(props) => (props.$active ? '#3b82f6' : '#cbd5e1')};
    opacity: 1;
  }
`;

const hints = [
  {
    icon: <IoSettings />,
    title: 'Customize your review form',
    details:
      "Click the <strong>Settings</strong> button to update your form's appearance, questions, and other preferences.",
  },
  {
    icon: <IoShareSocial />,
    title: 'Share your review form with customers',
    details:
      'Add your form link to your <strong>business card</strong>, share it on <strong>social media</strong>, or send it via <strong>email</strong> to start collecting reviews.',
  },
  {
    icon: <IoMail />,
    title: 'Send review invitations',
    details:
      'Use the <strong>Invite</strong> button to send personalized review requests directly to your customers.',
  },
  {
    icon: <IoLogoWhatsapp />,
    title: 'Share via messaging apps',
    details:
      'Use <strong>WhatsApp</strong> or <strong>SMS</strong> to share your review form link directly with customers.',
  },
  {
    icon: <IoEye />,
    title: 'Preview your review form',
    details:
      'Click <strong>See Live</strong> to view your review form exactly as your customers will see it.',
  },
];

export default function HintSlider() {
  const [isPaused, setIsPaused] = useState(false);
  const [currentHint, setCurrentHint] = useState(0);

  useEffect(() => {
    if (isPaused) return;

    const timer = setInterval(() => {
      setCurrentHint((current) => (current + 1) % hints.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [isPaused]);

  return (
    <HintText
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <HintContent>
        {hints.map((hint, index) => (
          <HintSlide
            key={index}
            $active={currentHint === index}
            className="hint-slide"
          >
            <HintTitle>
              {hint.icon}
              {hint.title}
            </HintTitle>
            <HintDetails
              dangerouslySetInnerHTML={{
                __html: hint.details,
              }}
            />
          </HintSlide>
        ))}
      </HintContent>
      <HintDots>
        {hints.map((_, index) => (
          <HintDot
            key={index}
            $active={currentHint === index}
            onClick={() => setCurrentHint(index)}
            type="button"
            aria-label={`Show hint ${index + 1}`}
          />
        ))}
      </HintDots>
    </HintText>
  );
}
