import { FaCrown } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { selectedSiteSelector } from '../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleUpgradeModal } from '../../store/app.slice';
import { SubscriptionPlan } from '../types/review.types';
import Button from './Button';
import FlexContainer from './layouts/flex-container';
import AppModal from './modal';

const Wrapper = styled(FlexContainer)`
  background: #fafaf8; /* Very light neutral color */
  padding: 12px 16px;
  border-radius: 8px;
  color: #666666; /* Neutral dark gray */
  margin: 0 0 16px;
  border: 1px solid #e6e6e6; /* Light border */
  box-shadow: none;
  gap: 15px;

  /* Optional: add a subtle left border accent */
  border-left: 3px solid #ffd700;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 945px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContentWrapper = styled(FlexContainer)`
  gap: 15px;
  align-items: center;

  @media (max-width: 945px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
`;

const PlanText = styled.span`
  font-weight: 600;
  color: #000;
`;

const UpgradeLink = styled.span`
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 4px;
  display: inline-block;

  &:hover {
    opacity: 0.8;
  }
`;

const ModalWrapper = styled(FlexContainer)`
  width: 100%;
  margin-top: 10px;

  .left {
    max-width: 350px;
    flex: 1;

    .desc {
      margin-bottom: 20px;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .right {
    flex: 1;
    max-width: 210px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
`;

const FeatureDescription = styled.p`
  color: #4a5568;
  font-size: 14px;
  line-height: 1.5;
  margin: 8px 0 0 0;
  padding: 0;
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const shine = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const AnimatedIconContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  animation: ${float} 2s ease-in-out infinite;

  @media (max-width: 945px) {
    width: 40px;
    height: 40px;
    margin: 0 0 8px;
  }

  svg {
    animation: ${shine} 2s ease-in-out infinite;
  }
`;

const TextWrapper = styled.span`
  @media (max-width: 945px) {
    width: 100%;
    display: block;
    line-height: 1.5;
  }
`;

const ButtonGroup = styled(FlexContainer)`
  gap: 8px;
  width: auto;
  margin-top: 12px;
  justify-content: flex-end;

  @media (max-width: 945px) {
    width: 100%;
    margin-top: 16px;
    justify-content: center;
  }
`;

const CancelButton = styled(Button)`
  background: transparent;
  color: #4a5568;
  border: 1px solid #e2e8f0;

  &:hover {
    background: #f7fafc;
  }
`;

type Props = {
  availableOn: string[];
};

export function PlanRestrictionMessage({ availableOn }: Props) {
  const dispatch = useAppDispatch();
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};

  if (plan !== 'free') {
    return null;
  }

  return (
    <Wrapper alignItems="center">
      <ContentWrapper>
        <AnimatedIconContainer>
          <FaCrown size={40} />
        </AnimatedIconContainer>
        <TextWrapper>
          Available on{' '}
          {availableOn.map((plan, index) => (
            <span key={plan}>
              {index > 0 && (
                <span>{index === availableOn.length - 1 ? ' or ' : ', '}</span>
              )}
              <PlanText>{plan}</PlanText>
            </span>
          ))}
          {'  '}
          {availableOn.length === 1 ? 'plan.' : 'plans.'}
        </TextWrapper>
      </ContentWrapper>
      <ButtonGroup>
        <Button
          rounded
          type="button"
          onClick={() => {
            dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
          }}
        >
          <b>Upgrade Now</b>
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
}

type ModalProps = {
  availableOn: string[];
  isOpen: boolean;
  closeModal: () => void;
  featureName: string;
  featureDescription: string;
};

export const PlanRestrictionModal = ({
  availableOn,
  isOpen,
  closeModal,
  featureName,
  featureDescription,
}: ModalProps) => {
  const dispatch = useAppDispatch();

  if (!closeModal) {
    return null;
  }

  return (
    <AppModal
      size="xs"
      onModalClose={closeModal}
      shouldOpenModal={!!isOpen}
      body={
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="12px"
          style={{ paddingTop: 30 }}
        >
          <AnimatedIconContainer
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FaCrown size={40} />
          </AnimatedIconContainer>

          <FeatureTitle>{featureName}</FeatureTitle>

          <FeatureDescription>{featureDescription}</FeatureDescription>

          <FeatureDescription style={{ textAlign: 'center' }}>
            <b>✨ Unlock this feature</b> by upgrading to{' '}
            {availableOn.map((plan, index) => (
              <span key={plan}>
                {index > 0 && (
                  <span>
                    {index === availableOn.length - 1 ? ' or ' : ', '}
                  </span>
                )}
                <PlanText>{plan}</PlanText>
              </span>
            ))}
            {'  '}
            plan.
          </FeatureDescription>

          <ButtonGroup style={{ width: '100%', justifyContent: 'center' }}>
            <Button
              size="small"
              rounded
              onClick={() => {
                closeModal();
                setTimeout(() => {
                  dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
                }, 200);
              }}
            >
              <b>Upgrade Now</b>
            </Button>
            <CancelButton size="small" rounded onClick={closeModal}>
              Maybe Later
            </CancelButton>
          </ButtonGroup>
        </FlexContainer>
      }
    />
  );
};
